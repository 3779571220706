import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_NODE } from "@/__main__/constants.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import { OVERLAY_IDS } from "@/game-val/constants.mjs";
import BenchmarkOverlayFixture from "@/game-val/fixtures/valorant-benchmark-overlay-fixture.json";
import { getMatchPosition } from "@/game-val/match-utils.mjs";
import { valBenchmarkRefs } from "@/game-val/refs.mjs";
import { getProfileIcon } from "@/game-val/static.mjs";
import {
  calcHeadshotPercent,
  getActiveSeason,
  getAgentName,
  getDeathmatchPositionColor,
  getHSLColor,
  getQueueName,
  getValorantRankImage,
  rankColor,
  translateValRankedTier,
} from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import BenchmarkOverlay from "@/shared/BenchmarkOverlay.jsx";
import { playerImageStyle } from "@/shared/BenchmarkOverlay.style.jsx";
import { calcRate } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TOGGLE_KEY = {
  key: "Tab",
  modifiers: {
    ctrlKey: IS_APP,
    altKey: false,
    shiftKey: false,
  },
};

const STAT_TYPES = {
  damage: "avgDamagePerRound",
  score: "avgScore",
  headshot: "headshotPercent",
  kda: "kda",
  kpm: "kpm",
};

const FRACTION_DIGITS = {
  [STAT_TYPES.damage]: 0,
  [STAT_TYPES.score]: 0,
  [STAT_TYPES.headshot]: 1,
  [STAT_TYPES.kda]: 1,
  [STAT_TYPES.kpm]: 1,
};

const getDerivedStats = ({
  kills,
  assists,
  deaths,
  score,
  roundsPlayed,
  playtimeMillis,
  damageStats,
  matches,
}) => {
  const derivedStats = {};
  derivedStats.headshotPercent = calcHeadshotPercent(damageStats);
  derivedStats.kda = calcRate(kills + assists, deaths, 1);
  derivedStats.avgScore = calcRate(score, roundsPlayed);
  derivedStats.kpm = calcRate(kills, (playtimeMillis || 0) / 60000);
  derivedStats.avgDamagePerRound = calcRate(damageStats?.damage, roundsPlayed);
  derivedStats.matches = matches;

  return derivedStats;
};

const getMatchStats = (stats) => {
  const derivedStats = {};
  derivedStats.headshotPercent = calcHeadshotPercent(
    {
      headshots: stats.headshots,
      bodyshots: stats.bodyshots,
      legshots: stats.legshots,
    },
    true,
    false,
  );
  derivedStats.kda = calcRate(stats.kills + stats.assists, stats.deaths, 1);
  derivedStats.avgScore = calcRate(stats.score, stats.roundsPlayed);
  derivedStats.kpm = calcRate(
    stats.kills,
    (stats.playDuration || 0) / 60000,
    1,
  );
  derivedStats.avgDamagePerRound = calcRate(
    stats.damagePerRound,
    stats.matchesPlayed || 1,
    1,
  );
  derivedStats.matches = stats.matchesPlayed || 1;

  return derivedStats;
};

const getMatchOutcomeText = (winStatus, isDeathmatch, matchPosition, t) => {
  if (isDeathmatch)
    return t("common:ordinalPlace", "{{place, ordinal}} Place", {
      place: matchPosition,
    });
  return winStatus === "win"
    ? t("common:victory", "Victory")
    : winStatus === "loss"
      ? t("common:defeat", "Defeat")
      : t("common:tie", "Tie");
};

const getMatchOutcomeColor = (winStatus, isDeathmatch, matchPosition) => {
  if (isDeathmatch) {
    return getDeathmatchPositionColor(matchPosition);
  }
  if (winStatus === "loss") return "var(--red)";
  if (winStatus === "win") return "var(--turq)";
  return "var(--shade3)";
};

const platformPath = getPlatformPath(false); // PC only

function ValBenchmarkOverlay() {
  const { t } = useTranslation();
  const route = useRoute();

  const { searchParams, currentPath, state: routeState } = route;
  const {
    profileId,
    playerId,
    matchId: matchIdParam,
    actId: actIdParam,
    queue: queueParam,
  } = routeState;
  const state = useSnapshot(readState);
  const isBenchmarkOverlayEnabled =
    state.settings?.valorant?.overlays?.isBenchmarkOverlayEnabled;
  const lowercaseName = profileId?.toLowerCase();
  const profile = state.val.profiles[lowercaseName];
  const playerTier = profile?.valorantProfile?.latestTier;
  const playerCardId = profile?.valorantProfile?.playerCardId;
  const lastAgentName = profile?.valorantProfile?.lastAgent?.name;
  const { id: latestActId } = getActiveSeason(state.val.content.acts);
  const latestMatchId =
    state.val.playerMatches[profile?.puuid]?.[latestActId]?.[platformPath]
      ?.overall?.[0]?.match?.gameId;
  const matchId = matchIdParam || latestMatchId;
  const actId = actIdParam || latestActId;
  const rawMatchData = state.val.match[matchId];
  const match = !(rawMatchData instanceof Error) ? rawMatchData : null;
  const isPostmatch = searchParams.get("postmatch") === "1";
  const queue = match?.queue || queueParam;
  const latestPlayerStats =
    state.val.latestPlayerStats[profile?.puuid]?.[queue]?.[0];
  const ranksStats = state.val.rankweapons;
  const ranks = state.val.content.ranks;

  const {
    val: { cms: { agents = [] } = {} },
  } = useSnapshot(readState);

  const player = match?.playerMatches?.find(
    (p) =>
      p.valorantProfile.riotAccount.puuid === profile?.puuid ||
      p.valorantProfile.riotAccount.puuid ===
        profile?.valorantProfile?.internalUuid,
  );

  const agent = useMemo(() => {
    let agent;
    const agentId = player?.agent?.uuid?.toLowerCase();
    if (agentId) {
      agent = agents?.find(
        (o) => o.uuid.toLowerCase() === agentId.toLowerCase(),
      );
    }

    return agent;
  }, [player?.agent?.uuid, agents]);
  const agentImg = agent?.images.matchtile.url;

  const openApp = useCallback(() => {
    blitzMessage(EVENTS.VALORANT_VIEW_BLITZ_APP, {
      matchId,
      profileId,
      actId,
    });
  }, [actId, matchId, profileId]);

  const isDeathmatch = match?.queue && match.queue === "deathmatch";
  const isEscalation = match?.queue && match?.queue === "ggteam";
  const isSnowball = match?.queue && match?.queue === "snowball";

  const statsPerRank = useMemo(() => {
    return Object.entries(ranksStats || {}).reduce((acc, [rank, stats]) => {
      acc[rank] = getDerivedStats(stats);
      return acc;
    }, {});
  }, [ranksStats]);

  const yourStats = useMemo(() => {
    if (!latestPlayerStats) return;
    return getMatchStats(latestPlayerStats);
  }, [latestPlayerStats]);

  const matchStats = useMemo(() => {
    if (!match || !match.gameId || match instanceof Error) return;
    if (!player) return;
    return getMatchStats(player);
  }, [match, player]);

  const playerTeamId = player?.teamId;
  const { playerTeamStats, enemyTeamStats } = useMemo(() => {
    const playerTeamStats = {},
      enemyTeamStats = {};
    if (match && playerTeamId) {
      for (const playerMatch of match.playerMatches) {
        if (
          playerMatch.valorantProfile.riotAccount.puuid ===
          player.valorantProfile.riotAccount.puuid
        )
          continue;
        const stats = getMatchStats(playerMatch);
        if (playerMatch.teamId === playerTeamId)
          playerTeamStats[playerMatch.id] = stats;
        if (playerMatch.teamId !== playerTeamId)
          enemyTeamStats[playerMatch.id] = stats;
      }
    }

    return { playerTeamStats, enemyTeamStats };
  }, [match, player, playerTeamId]);

  const playerRank = useMemo(() => {
    if (
      playerTier > 0 &&
      ranks &&
      ranks.find((r) => r.position === playerTier)
    ) {
      const rankTiers = ranks?.map((r) => r.position) || [];
      const nextPlayerTier = rankTiers.includes(playerTier + 1)
        ? playerTier + 1
        : playerTier;
      const nextRank = ranks.find((r) => r.position === nextPlayerTier);
      return nextRank?.tier;
    }

    return "iron";
  }, [playerTier, ranks]);

  const getChartData = useCallback(
    (statType) => {
      const list = [];
      const limit =
        (isDeathmatch || isEscalation || isEscalation) &&
        statType === STAT_TYPES.score
          ? 6
          : 10;

      if (yourStats) {
        list.push({
          key: "avg",
          image: getProfileIcon(playerCardId, lastAgentName),
          imageStyle: playerImageStyle,
          text: t("common:nAvg", "{{numberOfGames}} Avg.", {
            numberOfGames: yourStats.matches,
          }),
          data: yourStats[statType],
          dataString: getLocaleString(yourStats[statType], {
            maximumFractionDigits: FRACTION_DIGITS[statType],
            minimumFractionDigits: FRACTION_DIGITS[statType],
          }),
          fillColor: "var(--shade0-50)",
          hoverColor: "var(--shade0-50)",
        });
      }

      if (matchStats) {
        list.push({
          key: "match",
          image: agentImg,
          imageStyle: playerImageStyle,
          text:
            player?.valorantProfile?.riotAccount?.gameName ||
            getAgentName(t, agent?.key, agent?.name),
          data: matchStats[statType],
          dataString: getLocaleString(matchStats[statType], {
            maximumFractionDigits: FRACTION_DIGITS[statType],
            minimumFractionDigits: FRACTION_DIGITS[statType],
          }),
          fillColor: "#EFBF6C",
          hoverColor: "#EFBF6C",
          background:
            "linear-gradient(270deg, rgba(239, 191, 108, 0) 0%, rgba(239, 191, 108, 0.15) 100%)",
        });
      }

      const teamStats =
        isDeathmatch || isSnowball ? enemyTeamStats : playerTeamStats;
      let sortedStats = Object.entries(teamStats).sort(
        ([, a], [, b]) => b.data - a.data,
      );
      if (isDeathmatch && sortedStats.length > 4) {
        sortedStats = sortedStats.slice(0, 4);
      }
      for (const [id, stats] of sortedStats) {
        if (id === playerId) continue;
        if (list.length === limit) break;
        const player = match?.playerMatches?.find((p) => p.id === id);
        const agentId = player?.agent?.uuid;
        const agent = agents?.find(
          (o) => o.uuid.toLowerCase() === agentId?.toLowerCase(),
        );
        const agentKey = agent?.key;
        list.push({
          key: id,
          image: agent?.images.matchtile.url,
          imageStyle: playerImageStyle,
          text:
            player?.valorantProfile?.riotAccount?.gameName ||
            getAgentName(t, agentKey, agent?.name),
          data: stats[statType],
          dataString: getLocaleString(stats[statType], {
            maximumFractionDigits: FRACTION_DIGITS[statType],
            minimumFractionDigits: FRACTION_DIGITS[statType],
          }),
          fillColor: "var(--shade0-50)",
          hoverColor: `hsl(${getHSLColor(agent?.color)})`,
        });
      }

      const stats = statsPerRank[playerRank];
      if (
        !isDeathmatch &&
        !isEscalation &&
        !isSnowball &&
        list.length < limit &&
        stats
      ) {
        const data = {
          key: playerRank,
          image: getValorantRankImage({ tier: playerRank, size: "small" }),
          text: translateValRankedTier(t, playerRank),
          data: stats[statType],
          dataString: getLocaleString(stats[statType], {
            maximumFractionDigits: FRACTION_DIGITS[statType],
            minimumFractionDigits: FRACTION_DIGITS[statType],
          }),
          fillColor: "var(--shade0-50)",
          hoverColor: rankColor[playerRank] || rankColor.unrated,
        };

        list.push(data);
      }

      return list.sort((a, b) => b.data - a.data);
    },
    [
      agent?.key,
      agent?.name,
      agentImg,
      agents,
      enemyTeamStats,
      isDeathmatch,
      isEscalation,
      isSnowball,
      lastAgentName,
      match?.playerMatches,
      matchStats,
      player?.valorantProfile?.riotAccount?.gameName,
      playerCardId,
      playerId,
      playerRank,
      playerTeamStats,
      statsPerRank,
      t,
      yourStats,
    ],
  );

  const { title, titleColor } = useMemo(() => {
    if (!match || !match.gameId || match instanceof Error || !player) return {};
    const isDeathmatch = match.queue === "deathmatch";
    const deathmatchPosition = getMatchPosition(match.playerMatches, player);
    const winStatus = player.won ? "win" : "loss";
    const title = getMatchOutcomeText(
      winStatus,
      isDeathmatch,
      deathmatchPosition,
      t,
    );
    const titleColor = getMatchOutcomeColor(
      winStatus,
      isDeathmatch,
      deathmatchPosition,
    );

    return { title, titleColor };
  }, [match, player, t]);

  const tabs = useMemo(() => {
    return [
      {
        id: STAT_TYPES.score,
        text: t("common:stats.combatScore", "Combat Score"),
        value: matchStats?.[STAT_TYPES.score],
        valueStr: getLocaleString(matchStats?.[STAT_TYPES.score], {
          minimumFractionDigits: FRACTION_DIGITS[STAT_TYPES.score],
          maximumFractionDigits: FRACTION_DIGITS[STAT_TYPES.score],
        }),
        avgValue: yourStats?.[STAT_TYPES.score],
        avgValueStr: getLocaleString(yourStats?.[STAT_TYPES.score], {
          minimumFractionDigits: FRACTION_DIGITS[STAT_TYPES.score],
          maximumFractionDigits: FRACTION_DIGITS[STAT_TYPES.score],
        }),
      },
      ...(!match?.queue || (!isDeathmatch && !isSnowball && !isEscalation)
        ? [
            {
              id: STAT_TYPES.damage,
              text: t("common:damage", "Damage"),
              value: matchStats?.[STAT_TYPES.damage],
              valueStr: getLocaleString(matchStats?.[STAT_TYPES.damage], {
                minimumFractionDigits: FRACTION_DIGITS[STAT_TYPES.damage],
                maximumFractionDigits: FRACTION_DIGITS[STAT_TYPES.damage],
              }),
              avgValue: yourStats?.[STAT_TYPES.damage],
              avgValueStr: getLocaleString(yourStats?.[STAT_TYPES.damage], {
                minimumFractionDigits: FRACTION_DIGITS[STAT_TYPES.damage],
                maximumFractionDigits: FRACTION_DIGITS[STAT_TYPES.damage],
              }),
            },
          ]
        : []),
      ...(!match?.queue || (!isDeathmatch && !isSnowball && !isEscalation)
        ? [
            {
              id: STAT_TYPES.headshot,
              text: t("val:stats.headshotPercent", "Headshot %"),
              value: matchStats?.[STAT_TYPES.headshot],
              valueStr: getLocaleString(matchStats?.[STAT_TYPES.headshot], {
                minimumFractionDigits: FRACTION_DIGITS[STAT_TYPES.headshot],
                maximumFractionDigits: FRACTION_DIGITS[STAT_TYPES.headshot],
              }),
              avgValue: yourStats?.[STAT_TYPES.headshot],
              avgValueStr: getLocaleString(yourStats?.[STAT_TYPES.headshot], {
                minimumFractionDigits: FRACTION_DIGITS[STAT_TYPES.headshot],
                maximumFractionDigits: FRACTION_DIGITS[STAT_TYPES.headshot],
              }),
            },
          ]
        : []),
      ...(match?.queue && (isDeathmatch || isSnowball || isEscalation)
        ? [
            {
              id: STAT_TYPES.kda,
              text: t("common:stats.kda", "KDA"),
              value: matchStats?.[STAT_TYPES.kda],
              valueStr: getLocaleString(matchStats?.[STAT_TYPES.kda], {
                minimumFractionDigits: FRACTION_DIGITS[STAT_TYPES.kda],
                maximumFractionDigits: FRACTION_DIGITS[STAT_TYPES.kda],
              }),
              avgValue: yourStats?.[STAT_TYPES.kda],
              avgValueStr: getLocaleString(yourStats?.[STAT_TYPES.kda], {
                minimumFractionDigits: FRACTION_DIGITS[STAT_TYPES.kda],
                maximumFractionDigits: FRACTION_DIGITS[STAT_TYPES.kda],
              }),
            },
          ]
        : []),
      ...(match?.queue && (isSnowball || isEscalation)
        ? [
            {
              id: STAT_TYPES.kpm,
              text: t("common:stats.kpm", "Kills Per Min."),
              value: matchStats?.[STAT_TYPES.kpm],
              valueStr: getLocaleString(matchStats?.[STAT_TYPES.kpm], {
                minimumFractionDigits: FRACTION_DIGITS[STAT_TYPES.kpm],
                maximumFractionDigits: FRACTION_DIGITS[STAT_TYPES.kpm],
              }),
              avgValue: yourStats?.[STAT_TYPES.kpm],
              avgValueStr: getLocaleString(yourStats?.[STAT_TYPES.kpm], {
                minimumFractionDigits: FRACTION_DIGITS[STAT_TYPES.kpm],
                maximumFractionDigits: FRACTION_DIGITS[STAT_TYPES.kpm],
              }),
            },
          ]
        : []),
    ];
  }, [
    isDeathmatch,
    isEscalation,
    isSnowball,
    match?.queue,
    matchStats,
    t,
    yourStats,
  ]);

  useEffect(() => {
    // Testing purposes only
    if (!IS_APP && !IS_NODE) {
      updateRoute(currentPath, searchParams, {
        queue: BenchmarkOverlayFixture.queue,
        profileId: BenchmarkOverlayFixture.profileId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stats = useMemo(() => {
    return { recentMatchesCount: yourStats?.matches };
  }, [yourStats?.matches]);

  if (!profile || !yourStats || !isBenchmarkOverlayEnabled) return null;

  return (
    <BenchmarkOverlay
      game={"valorant"}
      overlayId={OVERLAY_IDS.valBenchmark}
      overlayIdAd={OVERLAY_IDS.valBenchmarkAd}
      matchId={matchId}
      icon={
        <img
          src={
            agentImg
              ? agentImg
              : getProfileIcon(
                  profile?.valorantProfile?.playerCardId,
                  profile?.valorantProfile?.lastAgent?.name,
                )
          }
          width={"var(--sp-10)"}
          height={"var(--sp-10)"}
          alt={"Player icon"}
        />
      }
      title={title}
      titleColor={titleColor}
      name={`${profile.gameName}#${profile.tagLine}`}
      stats={stats}
      queue={[`val:queues.${queue}`, getQueueName(queue)]}
      tabs={tabs}
      getChartData={getChartData}
      openApp={openApp}
      refetchEvent={EVENTS.VALORANT_REFETCH_STATS}
      refs={valBenchmarkRefs}
      toggleSettings={TOGGLE_KEY}
      showAd={isPostmatch}
    />
  );
}

export function meta() {
  return {
    title: [null, "VALORANT - Postmatch Overlay"],
    description: [null, "VALORANT Postmatch Overlay"],
  };
}

export default ValBenchmarkOverlay;
